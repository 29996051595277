/* Animations */

@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(140px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeBounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  
    40% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .submenu {
    animation: fadeIn 0.5s ease-in-out;
  }

  .fadeInUp {
    animation: fadeInUp 0.7s ease-in-out;
  }

  .fadeIn,
  .home__background__image {
    animation: fadeIn 0.7s ease-in-out;
  }

  .home__title__text {
    animation: fadeInUp 0.9s ease-in-out;
  }